import React, {FunctionComponent, useEffect, useState} from "react";
import Spinner from "../lib/components/Spinner";
import {ProfileModel} from "../lib/models/profile.models";
import useSaveProfile from "../lib/hooks/useSaveProfile";
import {useNavigate} from "react-router-dom";
import {CenterFull, PageCenter, Warning} from "../lib/components/Containers";
import FormDocument from "../components/FormDocument";
import {H1Center} from "../lib/components/fields/Common";
import Link from "@mui/material/Link";
import {useTranslation} from "react-i18next";
import {UrlsEnum} from "../enums/urls.enum";
import {filterFormData} from "../lib/utils/ProfileFunctions";
import useGetUser from "../lib/hooks/useGetUser";
import IconPleggit from "../lib/images/svg/logo.svg";


const UserDocument: FunctionComponent = () => {

  const onSaved = (updates: ProfileModel) => {
    console.log("saved")
  }

  const {t } = useTranslation()
  const navigate = useNavigate()

  const { data, isPending: isProfileLoading, isSuccess: isLoaded, isError: isLoadFailed, error: loadErrors }
    = useGetUser(true)
  const { mutate: save, isPending: isSaving, isSuccess: isSaved, error: saveErrors  } = useSaveProfile(onSaved)
  const [ state, setState] = useState<ProfileModel>()

  const updateProfile = (formData: ProfileModel) => {

    const { changed, filtered } = filterFormData(3, 3, true, formData, data)
    if (changed) {
      filtered.userId = data!.userId
      //console.log(filtered)
      save(filtered)
    }
  }


  useEffect(() => {
    if (data) {
      setState( prevState => { return {...prevState, ...data} })
    }
  }, [data]);


  const gotoTransactions = () => {
    navigate(UrlsEnum.TRANSACTIONS, {replace: true})
  }

  if (isSaved) {
    return (
      <PageCenter>
        <img src={IconPleggit} width={"170px"} alt={"logo"}/>
        <br/>
        <H1Center>{t("profile.text.update")}</H1Center>
        <br/>
        <Link variant={"body1"} sx={{cursor: "pointer"}}
              onClick={gotoTransactions}>
          {t("transaction.link.list")}

        </Link>
      </PageCenter>
    )
  }

  return (
  <>
    {isLoaded && state &&
      <FormDocument profile={state}
                    save={updateProfile}
                    saving={isSaving}
                    errors={saveErrors} />}


    {isProfileLoading &&
      <CenterFull>
        <Spinner />
      </CenterFull>}

    {isLoadFailed &&
      <Warning severity="warning">{loadErrors}</Warning>}
  </>

  )
}

export default UserDocument
