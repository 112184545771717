import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter} from "../Containers";
import {Table, TableBody, TableRow} from "@mui/material";
import CellTransType from "./CellTransType";
import {TransactionType, TransactionTypes} from "../../models/transaction.models";
import styled from "@emotion/styled";


interface Props {
  transType: TransactionType | null
  onChanged: (transType: TransactionType | null) => void
}

const FormTransType: FunctionComponent<Props> = ({transType, onChanged}) => {

  const { t } = useTranslation()

  return (

    <ColumnCenter>

      <h2>{t("transaction.create.step1.text")}</h2>
      <br/>

      <TableCentered>
        <TableBody>
          <TableRow>
            <CellTransType transType={TransactionTypes[0]} onClick={onChanged} selected={TransactionTypes[0] === transType}/>
            <CellTransType transType={TransactionTypes[1]} onClick={onChanged} selected={TransactionTypes[1] === transType}/>
          </TableRow>
          <TableRow>
            <CellTransType transType={TransactionTypes[2]} onClick={onChanged} selected={TransactionTypes[2] === transType}/>
            <CellTransType transType={TransactionTypes[3]} onClick={onChanged} selected={TransactionTypes[3] === transType}/>
          </TableRow>
        </TableBody>
      </TableCentered>

    </ColumnCenter>
  )

}

export default FormTransType

const TableCentered = styled(Table)`
  margin: 0 auto;
  width: initial;
`;
