import React, {FunctionComponent, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter} from "../Containers";
import {UseFormReturn} from "react-hook-form";
import {ProfileDataDocument} from "../../models/profile.models";
import {FormContainer} from "../fields/Common";


interface Props {
  formHook: UseFormReturn<ProfileDataDocument | any>
  onChanged: (profile: ProfileDataDocument, valid: boolean, dirty: boolean) => void
  children: any

}

const WrapDocument: FunctionComponent<Props> = ({children, formHook, onChanged}) => {

  console.log("formHook")
  console.log(formHook)

  const { watch, getValues, formState: {isValid, isDirty}} = formHook

  const docType = watch("docType")
  const docNum = watch("docNum")
  const docExpiryDate = watch("docExpiryDate")
  const docPictureFrontFile = watch("docPictureFrontFile")
  const docPictureBackFile = watch("docPictureBackFile")

  useEffect(() => {
    onChanged(getValues(), isValid, isDirty)
  }, [docType, docNum, docExpiryDate, docPictureFrontFile, docPictureBackFile, isValid])

  return (
    <ColumnCenter>
      <FormContainer>
        {children}
      </FormContainer>
    </ColumnCenter>
  )
}

export default WrapDocument