export interface SkinModel {
  name: string
  header: {
    background: string,
    icon:  string
    logout: boolean,
  }
}
 const SkinPleggit: SkinModel = {
   name: "Pleggit",
   header: {
     background: "#FFFFFF",
     icon:  "../images/png/pleggit-color.png",
     logout: true
  }
}

 const SkinAffareTrattore: SkinModel = {
  name: "AffareTrattore",
  header: {
    background: "#EF7B24",
    icon:  "/images/png/affaretrattore.png",
    logout: false
  }
}
 const Skins = new Map<string, SkinModel>([
  [SkinPleggit.name, SkinPleggit],
  [SkinAffareTrattore.name, SkinAffareTrattore]
]);

export const SkinDefault = SkinPleggit

export const resolveSkin = (name: string | null) => {
  return name ? (Skins.get(name) || SkinDefault) : SkinDefault
}