import {DEFAULT_COUNTRY_PHONE_CODE, DEFAULT_CURRENCY} from "../../Config";
import {AdvertModel, MarketplaceUserModel} from "../models/marketplace.models";

export const createMarketplaceUserModel = (advert: AdvertModel): MarketplaceUserModel => {
  return {
    email: "",
    password: "",
    userType: "private",
    firstName: "",
    lastName: "",
    legalName: "",
    phone: "",
    phoneCountryCode: advert.phoneCountryCode || DEFAULT_COUNTRY_PHONE_CODE,
    licensePlates: advert.plate,
    currency: advert.currency || DEFAULT_CURRENCY,
    price: advert.price,
    privacyTermsFlag: false
  }
}

export const isLandingPage = (query: URLSearchParams) => {
  return query.get("advert") !== null
}

export const getLandingSkin = (query: URLSearchParams) => {
  return "AffareTrattore"
}