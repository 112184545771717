import React, {FunctionComponent, useState} from "react";
import {ProfileModel} from "../lib/models/profile.models";
import useSaveProfile from "../lib/hooks/useSaveProfile";
import {EditMode} from "../lib/models/constants.enum";
import FormProfileWrapper from "../components/FormProfileWrapper"
import {createProfileModel} from "../lib/utils/ProfileFunctions";

type FormProfileHandle = React.ElementRef<typeof FormProfileWrapper>;

const ProfileCreate: FunctionComponent = () => {

  const onSaved = (updates: ProfileModel) => {
    setProfile( { ...profile, ...updates})
    formRef.current?.advance()
  }

  const formRef = React.useRef<FormProfileHandle>(null)
  const {mutate: save, isPending, error } = useSaveProfile(onSaved)
  const [profile, setProfile] = useState<ProfileModel>(createProfileModel())

  const createOrSaveProfile = (profile: ProfileModel) => {
    save(profile);
  }

  return (
    <FormProfileWrapper profile={profile}
                        ref={formRef}
                        mode={EditMode.CREATE}
                        save={createOrSaveProfile}
                        saving={isPending}
                        errors={error} />
  )
}

export default ProfileCreate