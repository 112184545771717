import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import InputTextField from "../fields/InputTextField";
import {UseFormReturn} from "react-hook-form";
import {ProfileDataDocument} from "../../models/profile.models";
import AutoCompleteField from "../fields/AutoCompleteField";
import CalendarField from "../fields/CalendarField";
import FileField from "../fields/FileField";
import {getDocumentOptions} from "../../utils/ProfileFunctions";


export interface FormDocumentProps  {
  profile: ProfileDataDocument
  formHook: UseFormReturn<ProfileDataDocument | any>
}
const FieldsDocumentPortrait: FunctionComponent<FormDocumentProps> = ({profile, formHook}) => {

  const {register, getValues, setValue, control, formState: {errors}} = formHook
  const {t} = useTranslation()

  return (

    <>

      <AutoCompleteField<string> name={"docType"}
                                 label={t("profile.label.docType")}
                                 optionValue={"value"}
                                 optionLabel={"label"}
                                 initialValue={profile.docType}
                                 register={register}
                                 control={control}
                                 error={!!errors["docType"]}
                                 uppercase={true}
                                 placeholder={t("profile.placeholder.docType")}
                                 autocompleteProps={{
                                  options: getDocumentOptions(t)
                                 }}/>


      <InputTextField name={"docNum"}
                      label={t("profile.label.docNum")}
                      required
                      register={register}
                      error={!!errors["docNum"]}
                      uppercase={true}
                      placeholder={t("profile.placeholder.docNum")}/>


      <CalendarField name={"docExpiryDate"}
                     label={t("profile.label.docExpiryDate")}
                     required
                     getValues={getValues}
                     setValue={setValue}
                     register={register}
                     error={!!errors["docExpiryDate"]}
                     placeholder={t("profile.placeholder.docExpiryDate")}
                     defaultValue={profile.docExpiryDate || ""}
                     disablePast={profile.docExpiryDate === ""}/>


      <FileField name={"docPictureFrontFile"}
                 link={profile.docPictureFrontLink || ""}
                 label={t("profile.label.docPictureFront")}
                 required
                 getValues={getValues}
                 setValue={setValue}
                 register={register}
                 error={!!errors["docPictureFrontFile"]}
                 placeholder={t("profile.placeholder.docPictureFront")}/>


      <FileField name={"docPictureBackFile"}
                 link={profile.docPictureBackLink || ""}
                 label={t("profile.label.docPictureBack")}
                 required
                 getValues={getValues}
                 setValue={setValue}
                 register={register}
                 error={!!errors["docPictureBackFile"]}
                 placeholder={t("profile.placeholder.docPictureBack")}/>
    </>

  )
}

export default FieldsDocumentPortrait