import {TransactionModel, TransactionPartyType} from "../models/transaction.models";

const isPartyType = (partyType: string, transaction?: TransactionModel) => {
  return (transaction && (transaction.partyType === partyType)) || false
}
export const isBuyerTransaction = (transaction?: TransactionModel): boolean => {
  return isPartyType(TransactionPartyType.buyer, transaction)
}
export const isSellerTransaction = (transaction?: TransactionModel): boolean => {
  return isPartyType(TransactionPartyType.seller, transaction)
}
export const isMatchableTransaction = (selling: TransactionModel, buying: TransactionModel): boolean => {
  return selling.licensePlates === buying.licensePlates && selling.price === buying.price && selling.currency === buying.currency
}
export const isSellerBuyerTransaction = (selling: TransactionModel, buying: TransactionModel): boolean => {
  return isMatchableTransaction(selling, buying)
    && selling.partyType === TransactionPartyType.seller && buying.partyType === TransactionPartyType.buyer
}