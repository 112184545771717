import React, {FunctionComponent} from "react";
import {HorizontalBox, SpaceBox} from "../lib/components/Containers";
import {useMediaQuery} from "@mui/material";

import FormLoginWrapper from "../lib/components/login/FormLoginWrapper";
import {useLocation} from "react-router-dom";
import {UserLoginModel} from "../lib/models/user.models";
import IconPleggit from "../lib/images/png/pleggit-color.png";
import AdvertHeader from "../components/AdvertHeader";


const Login: FunctionComponent = () => {

  const {state} = useLocation();
  const marketplace: boolean = state?.marketplace || false
  const user = state?.user as UserLoginModel || undefined
  const portrait = useMediaQuery("(max-width:640px)")



  return (
    <>
      <FormLoginWrapper user={user}>
        {marketplace &&
          <AdvertHeader portrait={portrait}
                        head={"Grazie"}
                        title={"Stiamo verificando i tuoi dati."}
                        subtitle={"Appena avrai attivato il tuo account potrai loggarti qui sotto e procedere all’acquisto."}
                        bold={false}/>
        }
        <SpaceBox size={portrait ? 0 : 50}/>

        {!marketplace &&
          <>
            <SpaceBox size={50}/>
            <HorizontalBox>
              <img src={IconPleggit} alt="example" height={"60px"}/>
            </HorizontalBox>
            <SpaceBox size={portrait ? 10 : 50}/>
          </>}
      </FormLoginWrapper>

      <SpaceBox size={portrait ? 0 : 50}/>
    </>
  )
}

export default Login
