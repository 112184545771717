import React from 'react'
import {Box} from "@mui/material"
import LabelValue from "./LabelValue";
import { SpaceBox, BorderBox, RowFlex,} from "../Containers"
import {useTranslation} from "react-i18next";
import {TransactionModel} from "../../models/transaction.models"
import BoxTrans from "./BoxTrans"
import {decodeCurrency} from "../../utils/Functions"
import {PaymentModel} from "../../models/user.models"

interface Props {
  transaction: TransactionModel
  payment: PaymentModel
}

const PanelTransPayment: React.FunctionComponent<Props> = ({transaction, payment}) => {

  const { t } = useTranslation()

  return (
    <BorderBox padding={"20px"}>
      <h3>{t("transaction.header.payment")}</h3>
      <SpaceBox size={8}/>
      <RowFlex style={{gap: "20px"}}>

        <BoxTrans header={"STEP 1"}
                  title={t("transaction.payment.step1.text")}
                  background={"#F4F4F4"}>
          <Box>
            <LabelValue label={t("transaction.label.recipient")} value={payment.recipient}/>
            <LabelValue label={t("transaction.label.iban")} value={payment.iban} copy={true}/>
          </Box>
        </BoxTrans>

        <BoxTrans header={"STEP 2"}
                  title={t("transaction.payment.step2.text")}
                  background={"#F4F4F4"}>
          <Box>
            <LabelValue label={t("transaction.label.currency")} value={decodeCurrency(transaction.currency)}/>
            <LabelValue label={t("transaction.label.price")} value={payment.amount.toLocaleString()}/>
          </Box>
        </BoxTrans>

        <BoxTrans header={"STEP 3"}
                  title={t("transaction.payment.step3.text")}
                  background={"#F4F4F4"}>
          <Box>
              <LabelValue label={t("transaction.label.reason")} value={transaction.pcode || ""} copy={true}/>
          </Box>
        </BoxTrans>
      </RowFlex>

    </BorderBox>
  )
}

export default PanelTransPayment