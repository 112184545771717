import React from 'react'
import {Col, PageLeft, Row, SpaceBox} from "../lib/components/Containers"
import styled from "@emotion/styled"
import {TextExtraLarge, TextPoppins} from "../lib/components/Texts";

interface Props {
  portrait?: boolean
  head: string
  title: string
  subtitle: string
  bold: boolean
}

const AdvertHeader: React.FunctionComponent<Props> = ({portrait= false, head, title, subtitle, bold}) => {

  return (
    <Container padding={portrait ? "20px": "50px"}>
      <PageLeft>
        <Row align={"center"}>
          <Col>
            <h1 style={{"whiteSpace": "inherit"}}>{head}</h1>
            <SpaceBox size={20}/>
            <TextExtraLarge $color={"#001964"}>{title}</TextExtraLarge>
            <SpaceBox size={20}/>
            <TextPoppins $color={"#001964"} $size={"24px"} $weight={bold ? 700 : 400}>{subtitle}</TextPoppins>
          </Col>
          { !portrait &&
          <Col>
            <Picture src="/images/png/pleggit-advert.png"/>
          </Col>}
        </Row>
      </PageLeft>
    </Container>
  )
}

const Container = styled.div<{padding: string}>`
  background-color: var(--color-grigio-chiaro);
  padding-top: ${props => props.padding};
  padding-bottom: ${props => props.padding};
`;





const Picture = styled.img`
  margin-left: 30px;
  margin-top: 1rem;
  max-height: 220px;
`;



export default AdvertHeader;
