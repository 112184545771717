import React, {FunctionComponent, useRef, useState} from "react"
import {
  HorizontalBox,
  PageLeft,
  SpaceBox,
  StyledLink,
  Warning
} from "../lib/components/Containers"
import {useTranslation} from "react-i18next"
import styled from "@emotion/styled"
import {useMediaQuery} from "@mui/material"
import FormMarketplace from "../components/FormMarketplace"
import AdvertBox from "../components/AdvertBox"
import {AdvertModel, MarketplaceUserModel} from "../lib/models/marketplace.models"
import {LoadingButtonRounded} from "../lib/components/fields/Common"
import IconNext from "@mui/icons-material/ArrowForward"
import useMarketPlace from "../lib/hooks/useMarketPlace"
import {useNavigate} from "react-router-dom";
import {UrlsEnum} from "../enums/urls.enum";
import {createMarketplaceUserModel} from "../lib/utils/MarketplaceFunctions";
import AdvertHeader from "../components/AdvertHeader";


const advert: AdvertModel = {
  seller: "Calogero Mannino",
  title: "Renault Clio 1.6 usata",
  currency: "EUR",
  price: 10999,
  plate: "FT123AG",
  picture: "https://s3-eu-west-1.amazonaws.com/eurekar-v2/uploads/images/original/_all-new_clio_-__dean_smith--16625.jpg"
}

const Marketplace: FunctionComponent = () => {

  const onRegistered = () => {
    doGotoLogin(true)
  }

  const {mutate: register, isPending, error} = useMarketPlace(onRegistered)
  const formData = useRef<MarketplaceUserModel>(createMarketplaceUserModel(advert))
  const [valid, setValid] = useState(false)
  const portrait = useMediaQuery("(max-width:640px)")
  const navigate = useNavigate();

  const { t } = useTranslation()

  const doRegister = () => {
    register(formData.current!)
  }

  const onChanged = (data: MarketplaceUserModel, valid: boolean) => {
    formData.current = data
    setValid(valid)
  }


  const gotoLogin = () => {
    doGotoLogin(true)
  }

  const doGotoLogin = (registered: boolean) => {
    let state = {marketplace: true} as any
    if (registered) {
      const data = formData.current!;
      state = { ...state, user: {username: data.email, password: data.password, userType: data.userType}}
    }
    console.log(state)
    navigate(UrlsEnum.LOGIN, {state: state})
  }

  return (
    <>
      <AdvertHeader portrait={portrait}
                    head={"Hai trovato l’occasione su Affare Trattore e vuoi pagare in sicurezza?"}
                    title={"Crea un profilo smart inserendo i dati richiesti qui sotto e lasciati guidare da Affare Trattore e Pleggit!"}
                    subtitle={"Il venditore riceverà il tuo pagamento solo quando la macchina sarà a casa tua!"}
                    bold={true}/>
      <PageLeft>
        <SpaceBox size={40}/>
        <StyledLink onClick={gotoLogin}>
          {t("register.text.already.registered")}
        </StyledLink>
        <SpaceBox size={30}/>
        <h2>{t("profile.header.create.your")}</h2>
        <SpaceBox size={30}/>
        <Row>
          <Col>
            <FormMarketplace data={formData.current!}
                             onChanged={onChanged}
                             fieldWidth={portrait ? "320px" : "400px"}/>
          </Col>
          <Col>
            <AdvertBox advert={advert} padding={portrait ? 20 : 30}/>
          </Col>
        </Row>
        <HorizontalBox mb={"10px"} sx={{alignSelf: "end"}}>
          <LoadingButtonRounded loading={isPending}
                                onClick={doRegister}
                                width={"160px"}
                                variant="contained"
                                endIcon={<IconNext />}
                                disabled={!valid}>
            {t("button.label.next")}
          </LoadingButtonRounded>
        </HorizontalBox>
        <Warning style={{visibility: error ? "visible": "hidden"}} severity={"error"}>{""+error}</Warning>
      </PageLeft>

    </>
  )
}

export default Marketplace


const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap-reverse;
  gap: 2%;

`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  min-width: 400px;
`
