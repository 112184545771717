import React, {FunctionComponent, useContext, useEffect, useMemo, useState} from "react";

import {useLocation, useNavigate} from "react-router-dom";
import Login from "./Login";
import Marketplace from "./Marketplace";
import {UrlsEnum} from "../enums/urls.enum";
import {AuthContext} from "../lib/auth/AuthContext";
import useGetUser from "../lib/hooks/useGetUser";
import Spinner from "../lib/components/Spinner";
import {CenterFull, Warning} from "../lib/components/Containers";
import { hasDocument } from "../lib/utils/ProfileFunctions";
import {getLandingSkin, isLandingPage} from "../lib/utils/MarketplaceFunctions";


export enum EnumMode {
  LOGIN = "login",
  MARKETPLACE = "marketplace"
}

const Home: FunctionComponent = () => {

  // trick to refresh current route
  const location = useLocation();
  return <HomeContent key={location.key}/>
}

const HomeContent: FunctionComponent = () => {

  const {isAuthenticated, isOperator, updateSkin} = useContext(AuthContext)

  const {search} = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search])
  const [mode, setMode] = useState<EnumMode>()
  const navigate = useNavigate()
  const user = useGetUser(isAuthenticated && !isOperator)

  useEffect(() => {
    if (isAuthenticated) {
      if (isOperator) {
        navigate(UrlsEnum.INITIAL, {replace: true})
      } else {
        if (user.isSuccess) {
          if (hasDocument(user.data)) {
            navigate(UrlsEnum.INITIAL, {replace: true})
          } else {
            navigate(UrlsEnum.DOCUMENTS, {replace: true})
          }
        }
      }
    } else if (isLandingPage(query)) {
      updateSkin(getLandingSkin(query))
      setMode(EnumMode.MARKETPLACE)
    } else {
      setMode(EnumMode.LOGIN)
    }
  }, [query, navigate, isAuthenticated, isOperator, user, updateSkin]);


  return (
    <>
      {mode === EnumMode.MARKETPLACE && <Marketplace/>}
      {mode === EnumMode.LOGIN &&  <Login/>}
      {user.isLoading && <CenterFull><Spinner/></CenterFull>}
      {user.error && <Warning severity={"error"}>{user.error}</Warning>}

    </>
  )
}

export default Home
