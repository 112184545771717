import {useQuery} from "@tanstack/react-query";
import ProfileService from "../services/ProfileService";
import {ProfileModel} from "../models/profile.models";

const getProfile = async (): Promise<ProfileModel> => {
  let { data } = await ProfileService.getProfile()
  return data
}


const useGetUser = (enabled: boolean) => {

  return useQuery<ProfileModel, string>({
    queryKey: ["profile"],
    queryFn: getProfile,
    enabled: enabled
  });

}
export default useGetUser;

