import React, {useState} from "react";
import {IconButton, Step, StepLabel, Stepper, Toolbar} from "@mui/material";
import {
  ProfileDataBirth,
  ProfileDataDocument,
  ProfileDataPersonal,
  ProfileDataResidence,
  ProfileModel
} from "../lib/models/profile.models";
import {useTranslation} from "react-i18next";
import {HorizontalBox, Page, SpaceBox, Warning} from "../lib/components/Containers";
import {ButtonRounded} from "../lib/components/fields/Common";
import IconNext from "@mui/icons-material/ArrowForward";
import FormPersonal from "../lib/components/profiles/FormPersonal";
import FormBirth from "../lib/components/profiles/FormBirth";
import FormResidence from "../lib/components/profiles/FormResidence";
import {EditMode} from "../lib/models/constants.enum";
import FormDocumentPortrait from "../lib/components/profiles/FormDocumentPortrait";
import Spinner from "../lib/components/Spinner";
import {updatedValuesAtIndex} from "../lib/utils/Functions";
import IconBack from "../lib/images/svg/back.svg";
import {
  getProfileBirth,
  getProfileDocument,
  getProfilePersonal,
  getProfileResidence
} from "../lib/utils/ProfileFunctions";


interface Props {
  mode: EditMode
  profile: ProfileModel
  step: number,
  maxSteps: number
  onBack: () => void
  onNext: (profile: ProfileModel) => void
  saving: boolean
  errors: any
}

const FormProfilePortrait: React.FunctionComponent<Props> = ({mode, profile, step, maxSteps, onBack, onNext, saving, errors }) => {

  const { t } = useTranslation()
  const [formData, setFormData] = useState<ProfileModel>(profile)
  const [validity, setValidity] = useState<boolean[]>([false, false, false, false])


  const buttonBackVisible = step > -1

  const handleNext = () => {
    onNext(formData)
  }

  const handleBack = () => {
    onBack()
  }

  const onPersonalChanged = (data: ProfileDataPersonal, valid: boolean) => {
    console.log("onPersonalChanged "+ valid+" -> "+ data.cf+" "+data.firstName+" "+data.lastName+" "+data.sex+" "+data.phone+" "+data.acceptedTerms)
    setFormData( {...formData, ...data })
    setValidity(updatedValuesAtIndex(0, valid, validity))
  }

  const onResidenceChanged = (data: ProfileDataResidence, valid: boolean) => {
    console.log("onResidenceChanged "+ valid +" -> "+data.residenceCountry+" "+data.residenceCity+" "+data.residenceProvince+" "+data.residenceAddress+" "+data.residenceCAP)
    setFormData( {...formData, ...data })
    setValidity(updatedValuesAtIndex(1, valid, validity))
  }

  const onBirthChanged = (data: ProfileDataBirth, valid: boolean) => {
    console.log("onBirthChanged "+ valid +" -> "+data.birthCountry+" "+data.birthCity+" "+data.birthProvince+" "+data.birthDate);
    setFormData( {...formData, ...data })
    setValidity(updatedValuesAtIndex(2, valid, validity))
  }

  const onDocumentChanged = (data: ProfileDataDocument, valid: boolean) => {
    console.log("onDocumentChanged "+ valid +" -> "+data.docType+" "+data.docNum+" "+data.docExpiryDate+" "+data.docPictureFrontFile+" "+data.docPictureBackFile)
    setFormData( {...formData, ...data })
    setValidity(updatedValuesAtIndex(3, valid, validity))
  }

  return (
    <Page>
      <Toolbar sx={{alignSelf: "start"}}>
        <IconButton onClick={handleBack}><img alt="back" src={IconBack}/></IconButton>
        <h1>{t(mode === EditMode.CREATE ? "profile.header.create.short" : "profile.header.update.short")}</h1>
      </Toolbar>
      <SpaceBox size={16}/>
      <HorizontalBox>
        <Stepper sx={{width:"320px"}} activeStep={step}>
          {[...Array(maxSteps)].map((label, index) => {
            return (
              <Step key={index}>
                <StepLabel/>
              </Step>
            )
          })}
        </Stepper>
      </HorizontalBox>
      <SpaceBox size={16}/>

      {step === 0 && (
        <FormPersonal profile={getProfilePersonal(profile)} onChanged={onPersonalChanged}/>
      )}
      {step === 1 && (
        <FormResidence profile={getProfileResidence(profile)} onChanged={onResidenceChanged}/>
      )}
      {step === 2 && (
        <FormBirth profile={getProfileBirth(profile)} onChanged={onBirthChanged}/>
      )}
      {step === 3 && (
        <FormDocumentPortrait profile={getProfileDocument(profile)} onChanged={onDocumentChanged}/>
      )}



      <Toolbar sx={{justifyContent: "center", marginTop:2, gap: 1, marginRight:"0", padding: "0"}}>
        {!saving && (
          <>
          <ButtonRounded variant="contained" color="secondary" size="medium"
                         onClick={handleBack}
                         sx={{visibility: buttonBackVisible ? "visible" : "hidden"}}>
           {t("button.label.back")}
          </ButtonRounded>
          <ButtonRounded variant="contained" size="medium"
                         onClick={handleNext}
                         endIcon={<IconNext />} disabled={!validity[step]}>
            {t("button.label.next")}
          </ButtonRounded>
          </>
        )}
        {saving && (
          <Spinner/>
        )}
      </Toolbar>

      { errors && (
        <Warning severity="warning">
            {errors}
        </Warning>
      )}

    </Page>
  )
}


export default FormProfilePortrait