import React from 'react';
import styled from "@emotion/styled";
import {TextSmall} from "../Texts";
import {useTranslation} from "react-i18next";
import {TransactionStates} from "../../models/transaction.models";

// TODO add more colors to states
const decodeColor = (state: number) => {
  switch (state) {
    case 0: return "#E1E1E1";
    case 1: return "#E82525";  // creation
    case 2: return "#F0B61F";  // matching
    case 3: return "#2E62FE";  // payment
    case 4: return "#2ECAFE";  // transfer
    case 5: return "#1DC275";  // checks
    case 6: return "#1DC275";  // completed
    default: return "#E1E1E1";
  }
}

const decodeState = (state: number) => {
  return  TransactionStates[state-1];
}

interface Props {
  state: number
  partyType: "seller" | "buyer"
}

const BoxTransState: React.FunctionComponent<Props> = ({state, partyType}) => {

  const { t } = useTranslation();
  const counterpart = partyType === "seller" ? "transaction.partyType.buyer" : "transaction.partyType.seller"

  return (
    <BoxColor boxColor={decodeColor(state)}>{t(decodeState(state), { partyType: t(counterpart)})}</BoxColor>
  )

}

export default BoxTransState;

const BoxColor = styled(TextSmall)<{boxColor: string}>`
  color: white;
  border-radius: 2px;
  background-color: ${props => props.boxColor};
  padding: 4px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
`;