import React, {FunctionComponent, useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter, SpaceBox} from "../Containers";
import {ProfileModel} from "../../models/profile.models";
import {FormContainer, H2Center} from "../fields/Common";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {TransactionPartyType} from "../../models/transaction.models";
import {AuthContext} from "../../auth/AuthContext";

interface Props {
  buyer: ProfileModel | null,
  seller: ProfileModel | null,
  onChanged: (buyer: ProfileModel | null, seller: ProfileModel | null) => void
}


const decodeType = (buyer: any, seller: any) => {
  return buyer !== null ? TransactionPartyType.buyer
    : seller !== null ? TransactionPartyType.seller
      : null
}

const FormTransActor: FunctionComponent<Props> = ({buyer, seller, onChanged}) => {

  const [actor, setActor] = useState(decodeType(buyer, seller));
  const {userId} = useContext(AuthContext)

  const { t } = useTranslation()


  const onChange =(event: React.MouseEvent<HTMLElement>, value: string) => {
    setActor(value)
    const user = {userId:userId} as ProfileModel
    if (value === TransactionPartyType.seller) {
      onChanged(null, user)
    } else {
      onChanged(user, null)
    }
  }

  return (

    <ColumnCenter>

      <H2Center>{t("transaction.create.step0.text")}</H2Center>

      <FormContainer>

        <SpaceBox size={16}/>

        <ToggleButtonGroup
          value={actor}
          exclusive
          onChange={onChange}>
          <ToggleButton value={TransactionPartyType.seller}>{t("transaction.partyType.seller")}</ToggleButton>
          <ToggleButton value={TransactionPartyType.buyer}>{t("transaction.partyType.buyer")}</ToggleButton>
        </ToggleButtonGroup>


      </FormContainer>

    </ColumnCenter>
  )
}

export default FormTransActor


