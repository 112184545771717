import React, {FunctionComponent, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import InputTextField from "../lib/components/fields/InputTextField";
import {MarketplaceUserModel} from "../lib/models/marketplace.models";
import styled from "@emotion/styled";
import validator from "validator";
import {privacyLabels} from "./FormProfileWrapper";
import CheckBoxField from "../lib/components/fields/CheckBoxField";
import PhoneField from "../lib/components/fields/PhoneField";
import {DEFAULT_COUNTRY_PHONE_CODE} from "../Config";
import NumericField from "../lib/components/fields/NumericField";
import RadioField from "../lib/components/fields/RadioField";
import {decodeCurrency} from "../lib/utils/Functions";
import {getUserTypes} from "../lib/utils/ProfileFunctions";

interface Props {
  onChanged: (user: MarketplaceUserModel, valid: boolean) => void
  data: MarketplaceUserModel
  fieldWidth?: string
}

const FormMarketplace: FunctionComponent<Props> = ({onChanged, data, fieldWidth = "400px"}) => {

  const { t } = useTranslation()
  const { register, watch, formState: { errors, isValid }, getValues, setValue}
    = useForm<MarketplaceUserModel>({ defaultValues: data, mode: "onChange" });

  const userType = watch("userType")
  const values = getValues();

  useEffect(() => {
    onChanged(values, isValid)
  }, [values, isValid])

  return (
    <Container>
      <InputTextField name={"email"}
                      label={t("login.label.username")}
                      error={!!errors["email"]}
                      width={fieldWidth}
                      required
                      register={register}
                      validator={validator.isEmail}
                      placeholder={t("login.placeholder.username")}/>

      <InputTextField name={"password"}
                      type={"password"}
                      label={t("login.label.password")}
                      width={fieldWidth}
                      required
                      error={!!errors["password"]}
                      register={register}
                      placeholder={t("login.placeholder.password")}/>

      <PhoneField name={"phone"}
                  nameCountryCode={"phoneCountryCode"}
                  label={t("profile.label.phone")}
                  width={fieldWidth}
                  required
                  setValue={setValue}
                  getValues={getValues}
                  defaultValue={""}
                  defaultCountryCodeValue={DEFAULT_COUNTRY_PHONE_CODE}
                  error={!!errors["phone"]}
                  register={register}
                  placeholder={t("profile.placeholder.phone")}/>

      <NumericField name={"price"}
                    label={t("transaction.label.price.long")}
                    defaultValue={data.price}
                    width={fieldWidth}
                    required
                    setValue={setValue}
                    format={"currency"}
                    currency={decodeCurrency(data.currency)}
                    error={!!errors["price"]}
                    register={register}
                    placeholder={t("transaction.placeholder.price")}/>

      <RadioField name={"userType"}
                  required
                  width={fieldWidth}
                  minWidth={fieldWidth}
                  defaultValue={data.userType || ""}
                  setValue={setValue}
                  getValues={getValues}
                  error={!!errors["userType"]}
                  register={register}
                  optionLabel={"label"}
                  optionValue={"value"}
                  options={getUserTypes(t)} />
      { /*
      <AutoCompleteField<string> name={"userType"}
                                 width={fieldWidth}
                                 label={t("register.label.userType")}
                                 optionValue={"value"}
                                 optionLabel={"label"}
                                 register={register}
                                 control={control}
                                 initialValue={""}
                                 error={!!errors["userType"]}
                                 uppercase={true}
                                 placeholder={t("select.placeholder.text")}
                                 autocompleteProps={{
                                   autoHighlight: true,
                                   noOptionsText: t("select.miss.text"),
                                   options: getUserTypes(t)
                                 }}/> */}

      { userType === "private" &&
        <>
        <InputTextField name={"firstName"}
                        label={t("profile.label.firstName")}
                        width={fieldWidth}
                        required
                        error={!!errors["firstName"]}
                        register={register}
                        uppercase={true}
                        placeholder={t("profile.placeholder.firstName")}/>
        <InputTextField name={"lastName"}
                        label={t("profile.label.lastName")}
                        width={fieldWidth}
                        required
                        error={!!errors["lastName"]}
                        register={register}
                        uppercase={true}
                        placeholder={t("profile.placeholder.lastName")}/>
        </>}

      { userType === "company" &&
        <InputTextField name={"legalName"}
                        label={t("register.label.legalName")}
                        width={fieldWidth}
                        error={!!errors["legalName"]}
                        register={register}
                        uppercase={true}
                        placeholder={t("register.placeholder.legalName")}/>}


      <CheckBoxField name={"privacyTermsFlag"}
                     label={privacyLabels}
                     fontSize={"16px"}
                     width={"100vw"}
                     padding={"0 0 16px 8px"}
                     error={!!errors["privacyTermsFlag"]}
                     required
                     getValues={getValues}
                     setValue={setValue}
                     register={register}/>


    </Container>
  )
}

export default FormMarketplace


export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;