import { ProfileModel } from "../models/profile.models";
import {TokenModel} from "../models/user.models";
import {setAuthToken} from "../services/http";

/** Helper class to be used only by AuthContext.
 * It updates directly the token of the http client.
 * Http client can access getOperatorKey */
class Auth {

  // region private
  private static instance: Auth

  private token: TokenModel | null = null
  private profile: ProfileModel | null = null
  private skin: string | null = null

  private constructor() {
    this.token = this.loadToken();
    this.profile = this.loadProfile();
    this.skin = this.loadSkin();
  }

  private static getInstance(): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  private load = (key: string): any | null => {
    let value = null
    let data = localStorage.getItem(key);
    if (data !== null) {
      try {
        value = JSON.parse(data)
      } catch (err) {
        console.error("failed parsing " + data)
      }
    }
    return value;
  }

  private save = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private loadToken = (): TokenModel | null => {
    const token = this.load("token")
    if (token != null) {
      setAuthToken(token.accessToken, token.userType);
    }
    return token;
  }

  private loadProfile = (): ProfileModel | null => {
    return this.load("profile")
  }

  private loadSkin = (): string | null => {
    return this.load("skin")
  }
  // endregion

  // region packge private get/set
  static getToken = () => {
    return Auth.getInstance().token
  }

  static getProfile = () => {
    return Auth.getInstance().profile
  }

  static getSkin = () => {
    return Auth.getInstance().skin
  }

  static updateToken = (token: TokenModel | null) => {
    Auth.getInstance().token = token
    if (token != null) {
      console.log("saving token")
      Auth.getInstance().save("token", token)
      setAuthToken(token.accessToken, token.userType);
    } else {
      console.log("clearing token")
      localStorage.removeItem("token");
      setAuthToken(null, null);
    }
  }

  static updateProfile = (profile: ProfileModel | null) => {
    Auth.getInstance().profile = profile
    if (profile != null) {
      Auth.getInstance().save("profile", profile);
    } else {
      localStorage.removeItem("profile");
    }
  }

  static updateSkin = (skin: string | null) => {
    Auth.getInstance().skin = skin
    if (skin != null) {
      Auth.getInstance().save("skin", skin);
    } else {
      localStorage.removeItem("skin");
    }
  }

  //endregion

  public static getOperatorKey = () => {
    return Auth.getInstance().token?.operatorKey || null
  }


}

export default Auth