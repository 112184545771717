import React from "react";
import {Route, Routes} from "react-router-dom";
import {UrlsEnum} from "../enums/urls.enum";

import ProfileList from "../pages/ProfileList";
import NotFound from "../pages/NotFound";
import ProfileEdit from "../pages/ProfileEdit";
import TransactionList from "../pages/TransactionList";
import TransactionCreate from "../pages/TransactionCreate";
import TransactionView from "../pages/TransactionView";
import ProfileCreate from "../pages/ProfileCreate";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Marketplace from "../pages/Marketplace";
import UserDocument from "../pages/UserDocument";


const Routing: React.FunctionComponent = () => {

  return (
      <Routes>
        <Route path={UrlsEnum.HOME} element={ <Home/> } />
        <Route path={UrlsEnum.LOGIN} element={ <Login/> } />
        <Route path={UrlsEnum.MARKETPLACE} element={ <Marketplace/> } />
        <Route path={UrlsEnum.TRANSACTIONS}
               element={<ProtectedRoute><TransactionList    /></ProtectedRoute>} />
        <Route path={UrlsEnum.TRANSACTIONS+"/:transactionId/view"}
               element={<ProtectedRoute><TransactionView    /></ProtectedRoute>} />

        <Route path={UrlsEnum.TRANSACTION_CREATE}
               element={<ProtectedRoute><TransactionCreate  /></ProtectedRoute>} />
        <Route path={UrlsEnum.PROFILES}
               element={<ProtectedRoute><ProfileList        /></ProtectedRoute>} />

        <Route path={UrlsEnum.PROFILES+"/:profileId/edit"}
               element={<ProtectedRoute><ProfileEdit        /></ProtectedRoute>} />

        <Route path={UrlsEnum.DOCUMENTS}
               element={<ProtectedRoute><UserDocument        /></ProtectedRoute>} />

        <Route path={UrlsEnum.PROFILES_CREATE}
               element={<ProtectedRoute><ProfileCreate   /></ProtectedRoute>} />

        <Route path="*" element={<NotFound/>}/>
      </Routes>
  )

}

export default Routing;