import React from 'react'

import {SpaceBox, BorderBox, RowFlex} from "../Containers"
import {useTranslation} from "react-i18next"
import {TransactionModel} from "../../models/transaction.models"
import {PinProps} from "./BoxTransPin";
import BoxTransUpload, {PropsUpload} from "./BoxTransUpload";
import BoxTransConfirm from "./BoxTransConfirm";


interface Props extends PropsUpload, PinProps {
  transaction: TransactionModel

}

const PanelTransConfirm: React.FunctionComponent<Props> = ({
     transaction,
     onPinSubmit, pinPosting, pinError, pinSuccess,
     onUploadFileChanged, onUploadFileSubmit, uploading,uploadError, uploadFile
  }) => {

  const { t } = useTranslation()


  const exchangeCodeUsed = transaction.counterpartInsertedEcode || false
  const counterpartCode = transaction?.counterpartECode || ""
  const counterpartCodeUsed = counterpartCode !== "" || pinSuccess
  const propertyTransferEnabled = exchangeCodeUsed && counterpartCodeUsed && !transaction.propertyTransURL


  return (
    <BorderBox padding={"20px"}>
      <h3>{t("transaction.header.property")}</h3>
      <SpaceBox size={16}/>
      <RowFlex style={{gap: "20px"}}>

        <BoxTransConfirm
          header={"STEP 1"}
          title={t("transaction.complete.confirm.text")}
          onPinSubmit={onPinSubmit}
          pinPosting={pinPosting}
          pinSuccess={pinSuccess}
          pinError={pinError}
          transaction={transaction}/>

        <BoxTransUpload header={t("STEP 2")}
                        title={t("transaction.complete.property.transfer.text")}
                        active={propertyTransferEnabled}
                        onUploadFileChanged={onUploadFileChanged}
                        onUploadFileSubmit={onUploadFileSubmit}
                        uploading={uploading}
                        uploadError={uploadError}
                        uploadFile={uploadFile}
                        background={"#F4F4F4"}/>
      </RowFlex>
    </BorderBox>
  )
}

export default PanelTransConfirm

