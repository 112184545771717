
export enum EditMode {
  CREATE = "create",
  UPDATE = "update"
}

export enum QueryMode {
  ID = "id",
  USERID = "userId",
  OPERATOR = "operatorId",
  BASIC = "operatorUserId"
}

