export enum UrlsEnum  {
  HOME = "/",
  LOGIN = "/login",
  MARKETPLACE = "/marketplace",
  INITIAL = "/transactions",
  TRANSACTIONS = "/transactions",
  TRANSACTION_CREATE = "/transactions/create",
  PROFILES = "/profiles",
  PROFILES_CREATE = "/profiles/create",

  DOCUMENTS = "/user/documents",

  PRIVACY = "http://www.pleggit.com/privacy",
  TERMS = "https://www.pleggit.com/terms"
}
