import React from 'react'
import {useTranslation} from "react-i18next"
import {BorderBox, Row, Col, SpaceBox} from "../lib/components/Containers"
import {AdvertModel} from "../lib/models/marketplace.models"
import LabelValue from "../lib/components/transactions/LabelValue"
import styled from "@emotion/styled"
import {decodeCurrency} from "../lib/utils/Functions";
import {DEFAULT_CURRENCY} from "../Config";

interface Props {
  advert: AdvertModel
  padding: number
}

const AdvertBox: React.FunctionComponent<Props> = ({advert, padding}) => {

  const { t } = useTranslation();

  return (
    <BorderBox color={"#F4F4F4"} background={"#F4F4F4"} marginBottom={"0"} padding={padding+"px"}>
      <Row align={"center"}>
        <Col>
          <Picture src={advert.picture}/>
        </Col>
        <SpaceBox size={padding}/>
        <Col>
          <LabelValue label={t("advert.label.header")} color={"#2E62FE"} size="32px" weight={700}  value={advert.title}/>
          <SpaceBox size={10}/>
          <LabelValue label={t("transaction.partyType.seller")} value={advert.seller}/>
          <Row width={"100%"}>
            <Col width={"50%"}>
              <LabelValue label={t("transaction.label.plate")} size="18px" value={advert.plate}/>
            </Col>
            <Col width={"50%"} align={"end"}>
              <LabelValue label={t("transaction.label.price")} color={"#001964"} weight={700} value={decodeCurrency(DEFAULT_CURRENCY)+" "+advert.price.toLocaleString()}/>
            </Col>
          </Row>
        </Col>
     </Row>
    </BorderBox>
  )
}



const Picture = styled.img`
  width: 170px;
  max-height: 400px;
  border-radius: 6px;
`;



export default AdvertBox;
