import React from 'react'
import styled from "@emotion/styled"
import {useTranslation} from "react-i18next"
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material"
import moment from "moment/moment"
import {TransactionModel} from "../../models/transaction.models"
import BoxTransState from "./BoxTransState"
import {decodeCurrency} from "../../utils/Functions"
import { StyledLink } from '../Containers'



interface Props {
  transaction: TransactionModel,
  onAction: (transaction: TransactionModel) => void
}


const RowTransaction: React.FunctionComponent<Props> = ({transaction, onAction}) => {

  const { t } = useTranslation();

  const onActionClicked = () => {
    onAction(transaction);
  }

  return (
    <TableRow id={"transaction-"+transaction.id}>
      <CellOuter>
        <TableInner className={"responsive"}>
          <TableHead>
            <TableRow>
              <CellHead width={"25%"} minwidth={"100px"} component={"th"}>{t("transaction.label.plate")}</CellHead>
              <CellHead width={"25%"} minwidth={"140px"} component={"th"}>{t("transaction.label.price")}</CellHead>
              <CellHead minwidth={"140px"} component={"th"}>{t("transaction.label.date")}</CellHead>
              <CellHead minwidth={"180px"} component={"th"}>{t("transaction.label.state")}</CellHead>
              <CellHead width={"auto"}>&nbsp;</CellHead>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <Cell data-label={t("transaction.label.plate")}>{transaction.licensePlates}</Cell>
              <Cell data-label={t("transaction.label.price")}>{decodeCurrency(transaction.currency)} {transaction.price.toLocaleString()}</Cell>
              <Cell data-label={t("transaction.label.date")}>
                { moment(transaction.creationTime,"YYYYMMDD").format(moment.localeData().longDateFormat('L'))}
              </Cell>
              <Cell data-label={t("transaction.label.state")}>
                <BoxTransState state={transaction.state} partyType={transaction.partyType}/>
              </Cell>
              <Cell align={"right"} width={"auto"}>
                <StyledLink onClick={onActionClicked}>{t("button.link.view")}</StyledLink>
              </Cell>
            </TableRow>
          </TableBody>
        </TableInner>
      </CellOuter>
    </TableRow>
  )

}

export default RowTransaction;



const CellOuter = styled(TableCell)`
  border-radius: 6px;
  border-bottom: inherit;
  border: 1px solid var(--color-grigio-scuro);
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 18px 30px;
  margin-bottom: 10px;

  @media screen and (max-width: 899px) {
    padding-bottom: 10px;
  }
`;

const TableInner = styled(Table)`
  padding: 0;
  margin: 0;
  border: 0;
`;

const CellHead = styled(TableCell)<{width? :string, minwidth?: string}>`
  padding: 0;
  border: 0;
  min-width: ${props => props.minwidth || "auto"};
  width: ${props => props.width || "auto"};
  font-size: 14px;
  color: var(--color-text-dark);
  text-transform: uppercase;
`;

const Cell = styled(TableCell)`
  padding: 0;
  border: 0;
  font-size: 18px;
  color: var(--color-text-dark);
`;