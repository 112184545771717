import React, {FunctionComponent, useState} from 'react';
import {ProfileDataDocument, ProfileModel} from "../lib/models/profile.models";
import {useForm} from "react-hook-form";
import { useTranslation } from 'react-i18next';
import {Column, HorizontalBox, MarginBox, PageLeft, Warning} from '../lib/components/Containers';
import {FormContainer, LoadingButtonRounded} from "../lib/components/fields/Common";
import WrapDocument from "../lib/components/profiles/WrapDocument";
import FieldsDocumentLandscape from "../lib/components/profiles/FieldsDocumentLandscape";
import {useMediaQuery} from "@mui/material";
import FieldsDocumentPortrait from "../lib/components/profiles/FieldsDocumentPortrait";
import IconNext from "@mui/icons-material/ArrowForward";


interface Props {
  profile: ProfileModel
  save: (profile: ProfileModel) => void
  saving: boolean,
  errors: any
}

const FormDocument: FunctionComponent<Props> = ({profile, save, errors, saving}) => {

  const portrait = useMediaQuery("(max-width:720px)")
  const formHook = useForm({defaultValues: profile, mode: "all"});
  const {t } = useTranslation()
  const [valid, setValid] = useState(false)
  const [formData, setFormData] = useState<ProfileModel>()


  const onChanged = (data: ProfileDataDocument, valid: boolean) => {
    console.log("onDocumentChanged "+ valid +" -> "+data.docType+" "+data.docNum+" "+data.docExpiryDate+" "+data.docPictureFrontFile+" "+data.docPictureBackFile)
    setFormData(data as ProfileModel)
    setValid(valid)
  }

  const doSave = () => {
    save(formData!)
  }

  return (

    <PageLeft>
      <MarginBox width={"100%"} top={"30px"} bottom={"20px"}>
        <h1>{t(portrait ? "profile.header.complete.short" : "profile.header.complete")}</h1>
      </MarginBox>


      <Column>

        {portrait &&
          <h3>{t("profile.title.document")}</h3>}

        <FormContainer>
          <WrapDocument formHook={formHook} onChanged={onChanged}>
            {portrait && <FieldsDocumentPortrait formHook={formHook} profile={profile}/>}
            {!portrait && <FieldsDocumentLandscape formHook={formHook} profile={profile}/>}
          </WrapDocument>
        </FormContainer>

      </Column>

      <HorizontalBox sx={{alignSelf: "end", marginTop:2, marginBottom:2, gap: 5}}>

        <LoadingButtonRounded loading={saving}
                              onClick={doSave}
                              width={"160px"}
                              variant="contained"
                              endIcon={<IconNext />}
                              disabled={!valid}>
          {t("button.label.next")}
        </LoadingButtonRounded>
      </HorizontalBox>

      { errors && (
        <Warning severity="warning">
          {errors}
        </Warning>
      )}

    </PageLeft>




  )
}

export default FormDocument