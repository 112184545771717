import {useQuery} from "@tanstack/react-query";
import {TransactionModel} from "../models/transaction.models";
import TransactionService from "../services/TransactionService";


const loadTransaction = async (transactionId: string): Promise<TransactionModel> => {
  const { data } = await TransactionService.load(transactionId)
  return data
};

const useGetTransaction = (id?: string) => {

  return useQuery<TransactionModel, string>({
    queryKey: ["transaction", id!],
    enabled: id !== undefined,
    queryFn: () => loadTransaction(id!)
  });
}
export default useGetTransaction;

