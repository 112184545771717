import {cleanupData, isEmpty} from "./Functions";
import {
  ProfileDataBirth,
  ProfileDataBirthDocument,
  ProfileDataDocument,
  ProfileDataPersonal,
  ProfileDataPersonalResidence,
  ProfileDataResidence,
  ProfileModel
} from "../models/profile.models";

export const createProfileModel = (): ProfileModel => {
  return {
    userId: "",

    cf: "",
    firstName: "",
    middleNames: "",
    lastName: "",
    sex: "",
    phone: "",
    phoneCountryCode: "",

    residenceCity: "",
    residenceProvince: "",
    residenceAddress: "",
    residenceCountry: "",
    residenceCAP: "",

    birthDate: "",
    birthCity: "",
    birthCountry: "",
    birthProvince: "",

    docType: "",
    docNum: "",
    docExpiryDate: "",
    docPictureFrontFile: null,
    docPictureBackFile: null,
    docPictureFrontLink: "",
    docPictureBackLink: "",

    acceptedTerms: false,
    acceptedSpecialTerms: false,
    acceptedPrivacy: false
  }
}
export const getProfilePersonal = (data: ProfileModel): ProfileDataPersonal => {
  return {
    userId: data.userId,
    cf: data.cf,
    firstName: data.firstName,
    middleNames: data.middleNames,
    lastName: data.lastName,
    sex: data.sex,
    phone: data.phone,
    phoneCountryCode: data.phoneCountryCode,

    acceptedTerms: data.acceptedTerms,
    acceptedSpecialTerms: data.acceptedSpecialTerms,
    acceptedPrivacy: data.acceptedPrivacy
  }
}
export const getProfileResidence = (data: ProfileModel): ProfileDataResidence => {
  return {
    residenceCity: data.residenceCity,
    residenceProvince: data.residenceProvince,
    residenceAddress: data.residenceAddress,
    residenceCountry: data.residenceCountry,
    residenceCAP: data.residenceCAP
  }
}
export const getProfilePersonalResidence = (data: ProfileModel): ProfileDataPersonalResidence => {
  return {...getProfilePersonal(data), ...getProfileResidence(data)}
}
export const getProfileBirth = (data: ProfileModel): ProfileDataBirth => {
  return {
    birthDate: data.birthDate,
    birthCity: data.birthCity,
    birthCountry: data.birthCountry,
    birthProvince: data.birthProvince
  }
}
export const getProfileDocument = (data: ProfileModel): ProfileDataDocument => {
  return {
    docType: data.docType,
    docNum: data.docNum,
    docExpiryDate: data.docExpiryDate,
    docPictureFrontLink: data.docPictureFrontLink,
    docPictureBackLink: data.docPictureBackLink,
    docPictureFrontFile: data.docPictureFrontFile,
    docPictureBackFile: data.docPictureBackFile,
  };
}
export const getProfileDataBirthDocument = (data: ProfileModel): ProfileDataBirthDocument => {
  return {...getProfileBirth(data), ...getProfileDocument(data)}
}
export const getRecipient = (profile?: ProfileModel) => {
  return profile?.legalName ? profile.legalName
    : profile?.lastName ? profile?.firstName + " " + profile?.lastName
      : "NA"
}
export const DocSide = {
  front: "front",
  back: "back"
}
const fieldsPortrait = [
  ["cf", "firstName", "middleNames", "lastName", "sex", "phone", "phoneCountryCode", "acceptedTerms"],
  ["residenceCity", "residenceAddress", "residenceCountry", "residenceCAP", "residenceProvince"],
  ["birthDate", "birthCity", "birthCountry", "birthProvince"],
  ["docType", "docNum", "docExpiryDate", "docPictureFrontFile", "docPictureFrontLink", "docPictureBackFile", "docPictureBackLink"]
]
const fieldsLandscape = [
  ["cf", "firstName", "middleNames", "lastName", "sex", "phone", "phoneCountryCode", "residenceCity", "residenceAddress", "residenceCountry", "residenceCAP", "residenceProvince", "acceptedTerms"],
  ["birthDate", "birthCity", "birthCountry", "birthProvince", "docType", "docNum", "docExpiryDate", "docPictureFrontFile", "docPictureFrontLink", "docPictureBackFile", "docPictureBackLink"]
]
export const filterFormData = (step: number, lastStep: number, portrait: boolean, updated: any, source: any) => {
  const fields = portrait ? fieldsPortrait : fieldsLandscape
  let changed = !fields[step].every((field => (updated[field] === source[field]) || (isEmpty(updated[field]) && isEmpty(source[field]))))
  if (step === lastStep && (updated["docPictureFrontFile"] !== null || updated["docPictureBackFile"] !== null)) {
    changed = true
  }
  const filtered = cleanupData(updated, fields[step]) as unknown as ProfileModel
  return {filtered, changed}
}
export const getDocumentOptions = (t: any) => {
  return [
    {value: "ci", label: t("profile.doctype.ci")},
    {value: "passport", label: t("profile.doctype.passport")},
    {value: "licence", label: t("profile.doctype.license")}
  ]
}
export const hasDocument = (profile: ProfileModel) => {
  return !isEmpty(profile.docType) && !isEmpty(profile.docNum) && !isEmpty(profile.docExpiryDate) && !isEmpty(profile.docPictureFrontLink) && !isEmpty(profile.docPictureBackLink)
}
export const getSexOptions = (t: any) => {
  return [
    {value: "M", label: t("profile.sex.male")},
    {value: "F", label: t("profile.sex.female")}
  ]
}
export const getUserTypes = (t: any) => {
  return [
    {value: "private", label: t("register.userType.private")},
    {value: "company", label: t("register.userType.company")}
  ]
}