import * as React from 'react'
import {createContext, ReactNode, useState} from 'react'
import {TokenModel} from "../models/user.models";
import Auth from "./Auth";
import {ProfileModel} from "../models/profile.models";



type PropsProvider = {
  children?: ReactNode
}

type PropsContext = {
  profile: ProfileModel | null
  skin: string | null
  userId: string | null

  updateToken: (token: TokenModel | null) => void
  updateProfile: (profile: ProfileModel | null) => void
  updateSkin: (skin: string | null) => void
  clearAuth: () => void

  isAuthenticated: boolean
  isOperator: boolean
}

const AuthContext = createContext<PropsContext>({
  profile: null,
  skin: null,
  userId: null,

  updateToken: (token: TokenModel | null) =>  {},
  updateProfile: (profile: ProfileModel | null) =>  {},
  updateSkin: (skin: string | null) => {},
  clearAuth: () =>  {},

  isAuthenticated: false,
  isOperator: false
})

const AuthProvider = (props: PropsProvider) => {

  const [token, setToken] = useState<TokenModel | null>(Auth.getToken())
  const [profile, setProfile] = useState<ProfileModel | null>(Auth.getProfile())
  const [skin, setSkin] = useState<string| null>(Auth.getSkin())


  const updateToken = (token: TokenModel | null) => {
    Auth.updateToken(token)
    setToken(token)
  }

  const updateProfile = (profile: ProfileModel | null) => {
    Auth.updateProfile(profile)
    setProfile(profile)
  }

  const updateSkin = (skin: string | null) => {
    Auth.updateSkin(skin)
    setSkin(skin)
  }

  const clearAuth = () => {
    updateToken(null)
    updateProfile(null)
    updateSkin(null)

  }

  //console.log("isAuthenticated " + (!!token))
  //console.log("isOperator " + (!!token?.operatorKey))

  return (
    <AuthContext.Provider value={{
        userId: token?.userId || null,
        isAuthenticated: !!token,
        isOperator: !!token?.operatorKey,
        profile, updateProfile,
        skin, updateSkin,
        updateToken, clearAuth }}>
      {props.children}
    </AuthContext.Provider>)
}

export { AuthContext, AuthProvider }