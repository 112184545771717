import React from 'react'
import {TextPoppins, TextSmall} from "../Texts"
import {Box} from "@mui/material"
import CopyClip from "../CopyClip";
import {LabelValueModel} from "../../models/misc.models";
import {MarginBox} from "../Containers";


interface Props extends LabelValueModel {
  copy?: boolean
  size?: string
  color?: string
  weight?: number,
  height?: number
}

const LabelValue: React.FunctionComponent<Props> = ({label, value, size= "18px", weight = 400, color = "#001964", height = 100, copy= false}) => {

  return (
    <Box mt={1} minHeight={"48px"}>
      <TextSmall $color={"#C0C0C0"}>{label}</TextSmall>
      <MarginBox top={"10px"}/>
      <CopyClip content={value} visible={copy}>
        <TextPoppins $color={color} $size={size} $weight={weight} $height={height}>{value}</TextPoppins>
      </CopyClip>
    </Box>
  )
}

export default LabelValue