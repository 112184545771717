import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import {UseFormReturn} from "react-hook-form";
import {ProfileDataDocument} from "../../models/profile.models";
import AutoCompleteField from "../fields/AutoCompleteField";
import CalendarField from "../fields/CalendarField";
import Box from "@mui/material/Box";
import InputTextField from "../fields/InputTextField";
import FileField from "../fields/FileField";
import {BorderBox, MarginBox} from "../Containers";
import {getDocumentOptions} from "../../utils/ProfileFunctions";



export interface FormDocumentProps  {
  profile: ProfileDataDocument
  formHook: UseFormReturn<ProfileDataDocument | any>
}

const FieldsDocumentLandscape: FunctionComponent<FormDocumentProps> = ({profile, formHook}) => {

  const {register, getValues, setValue, control, formState: {errors}} = formHook
  const {t} = useTranslation()

  return (
    <>
      <BorderBox padding={"30px 0"}>
        <MarginBox left={"40px"} bottom={"20px"}><h3>{t("profile.title.document")}</h3></MarginBox>

        <Box mb={"20px"}>
          <AutoCompleteField<string> name={"docType"}
                                     width={"50%"}
                                     padding={"0 10px 0 40px"}
                                     label={t("profile.label.docType")}
                                     optionValue={"value"}
                                     optionLabel={"label"}
                                     initialValue={profile.docType}
                                     register={register}
                                     control={control}
                                     error={!!errors["docType"]}
                                     uppercase={true}
                                     placeholder={t("profile.placeholder.docType")}
                                     autocompleteProps={{
                                       options: getDocumentOptions(t),
                                       noOptionsText: t("select.miss.text"),
                                     }}/>


          <InputTextField name={"docNum"}
                          width={"50%"}
                          padding={"0 10px 0 0"}
                          label={t("profile.label.docNum")}
                          required
                          register={register}
                          error={!!errors["docNum"]}
                          uppercase={true}
                          placeholder={t("profile.placeholder.docNum")}/>
        </Box>

        <Box>
          <CalendarField name={"docExpiryDate"}
                         width={"33%"}
                         padding={"0 10px 0 40px"}
                         label={t("profile.label.docExpiryDate")}
                         placeholder={t("profile.placeholder.docExpiryDate")}
                         required
                         getValues={getValues}
                         setValue={setValue}
                         register={register}
                         error={!!errors["docExpiryDate"]}
                         defaultValue={profile.docExpiryDate || ""}
                         disablePast={profile.docExpiryDate === ""}/>


          <FileField name={"docPictureFrontFile"}
                     width={"33%"}
                     padding={"0 10px 0 0"}
                     link={profile.docPictureFrontLink || ""}
                     label={t("profile.label.docPictureFront")}
                     required
                     getValues={getValues}
                     setValue={setValue}
                     register={register}
                     error={!!errors["docPictureFrontFile"]}
                     placeholder={t("profile.placeholder.docPictureFront")}/>


          <FileField name={"docPictureBackFile"}
                     width={"33%"}
                     padding={"0 10px 0 0"}
                     link={profile.docPictureBackLink || ""}
                     label={t("profile.label.docPictureBack")}
                     required
                     getValues={getValues}
                     setValue={setValue}
                     register={register}
                     error={!!errors["docPictureBackFile"]}
                     placeholder={t("profile.placeholder.docPictureBack")}/>

        </Box>

      </BorderBox>
    </>

  )

}

export default FieldsDocumentLandscape