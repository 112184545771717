import http from "./http"
import {MarketplaceUserModel} from "../models/marketplace.models";

const BASE_URL = process.env.REACT_APP_MARKETPLACE_API_ENDPOINT


const register = (data: MarketplaceUserModel) => {
  return http.post("/registrations", data, {baseURL: BASE_URL})
}


const MarketPlaceService = {
  register
}

export default MarketPlaceService