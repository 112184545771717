import {useMutation} from "@tanstack/react-query";
import FileService from "../services/FileService";
import TransactionService from "../services/TransactionService";
import {TransactionDocType, TransactionPartyType, TransCreateModel} from "../models/transaction.models";
import {useContext} from "react";
import {AuthContext} from "../auth/AuthContext";

export interface ExchangeCodeParams {
  transactionId: string,
  code: string
}

export interface PropertyTransferParams {
  transactionId: string,
  file: File,
  userId: string
}



const doCreateTransaction = async (transactionCreator: (data: TransCreateModel) => Promise<any>, transaction: TransCreateModel) => {
  const {file, ...transData} = transaction
  return await transactionCreator(transData).then( ( async (creation) : Promise<string> => {
    const userId = transData.user
    const transactionId = creation.data.transactionId as string
    if (file) {
      return uploadPropertyProof(transactionId, file, userId)
    } else {
      return transactionId
    }
  }))
}


const createTransaction = async (transaction: TransCreateModel) => {
  return doCreateTransaction(TransactionService.create, transaction)
}

const createOperatorTransaction = async (transaction: TransCreateModel) =>  {
  return doCreateTransaction(TransactionService.createOperator, transaction)
}


const uploadPropertyProof = (transactionId: string, file: File, userId: string): Promise<string> => {
  return uploadPropertyProofDocument(transactionId, file, TransactionDocType.pp, userId)
}

const uploadPropertyTransfer = (transactionId: string, file: File, userId: string) => {
  return uploadPropertyTransferDocument(transactionId, file, TransactionDocType.pt, userId)
}

const uploadPropertyProofDocument = async (transactionId: string, file: File, docType: TransactionDocType, userId: string) => {
  return await FileService.uploadPropertyDocument(transactionId, docType, file, userId).then((async (upload) => {
    //console.log(upload.data)
    const filepath = upload.data.filepath
    return await TransactionService.postPPLink(transactionId, filepath, userId).then((async (link) => {
      //console.log(link.data)
      return transactionId
    }))
  }))
}

const uploadPropertyTransferDocument = async (transactionId: string, file: File, docType: TransactionDocType, userId: string) => {
  return await FileService.uploadPropertyDocument(transactionId, docType, file, userId).then((async (upload) => {
    //console.log(upload.data)
    const filepath = upload.data.filepath
    return await TransactionService.postPTLink(transactionId, filepath, userId).then((async (link) => {
      //console.log(link.data)
      return transactionId
    }))
  }))
}
const exchangeCode = async (params: ExchangeCodeParams) => {
  return (await TransactionService.postExchangeCode(params.transactionId, params.code)).data
}

const exchangeOperatorCodes = async (params: ExchangeCodeParams[]) => {
  await exchangeCode(params[0]).then( async () => {
    if (params.length > 1) {
      await exchangeCode(params[1]).then( async () => {
        return true
      })
    } else {
      return true
    }
  })
}

const createOperatorTransactions = async (transactions: TransCreateModel[]) => {
  return await createOperatorTransaction(transactions[0]).then(async (firstTransactionId) => {
    if (transactions.length > 1) {
      const secondTransactionId = await createOperatorTransaction(transactions[1]).then(async (transactionId) => {
        return transactionId
      })

      if (transactions[1].partyType === TransactionPartyType.seller) {
        return secondTransactionId
      }
    }
    return firstTransactionId
  })
}




const useSaveTransaction = (callback?: (result?: any) => void ) => {
  const {isOperator} = useContext(AuthContext)

  const mutationSaveTransaction = useMutation({
    mutationFn: (transaction: TransCreateModel) => createTransaction(transaction),
    onSuccess(result) { callback && callback(result) }
  })

  const mutationSaveTransactions = useMutation({
    mutationFn: (transactions: TransCreateModel[]) => {
      if (isOperator) {
        return createOperatorTransactions(transactions)
      } else {
        return createTransaction(transactions[0])
      }
    },
    onSuccess(result) { callback && callback(result) }
  })

  const mutationExchangeCode = useMutation({
    mutationFn: (params: ExchangeCodeParams) => exchangeCode(params),
    onSuccess() { callback && callback() }
  })

  const mutationExchangeOperatorCodes = useMutation({
    mutationFn: (params: ExchangeCodeParams[]) => exchangeOperatorCodes(params),
    onSuccess() { callback && callback() }
  })

  const mutationPropertyProof = useMutation({
    mutationFn: (params: PropertyTransferParams) => uploadPropertyProof(params.transactionId, params.file, params.userId),
    onSuccess() { callback && callback() }
  })

  const mutationPropertyTransfer = useMutation({
    mutationFn: (params: PropertyTransferParams) => uploadPropertyTransfer(params.transactionId, params.file, params.userId),
    onSuccess() { callback && callback() }
  })

  return {
    saveTransaction: mutationSaveTransaction,
    saveTransactions: mutationSaveTransactions,
    savePropertyTransfer: mutationPropertyTransfer,
    savePropertyProof: mutationPropertyProof,
    saveExchangeCode: mutationExchangeCode,
    saveExchangeCodes: mutationExchangeOperatorCodes
  }
}
export default useSaveTransaction;

