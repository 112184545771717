import styled from "@emotion/styled";

export const TextPoppins = styled.div<{$color?: string, $size?: string, $weight?: number, $height?:number}>`
  color: ${props => props.$color || "#000"};
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  font-weight:  ${props => props.$weight || 400};
  ${props => props.$size && `
    font-size: ${props.$size};
  `}
  line-height:  ${props => props.$height || 100}%;
`;


export  const TextSmall = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-small);
`;

export const TextNormal = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-normal);
`;

export const TextMedium = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-medium);
`;

export const TextLarge = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-large);
`;


export const TextExtraLarge = styled(TextPoppins)<{$color?: string}>`
  font-size: var(--font-xlarge);
`;



export const TextLargeStrong = styled(TextLarge)<{$color?: string}>`
  font-weight: 700;
`;

