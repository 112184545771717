import React, {useState} from 'react'

import {SpaceBox, ColumnCenter, Warning, HorizontalBox} from "../Containers"
import {useTranslation} from "react-i18next"
import {TransactionModel} from "../../models/transaction.models"
import BoxTrans from "./BoxTrans"
import PinInput from 'react-pin-input'
import {LoadingButtonRounded} from "../fields/Common"
import {TextLarge} from "../Texts";
import IconCheck from "@mui/icons-material/Check";

// const isNotNull = (object: unknown) => {
//   return object !== undefined && object !== null
// }

export interface PinProps {
  onPinSubmit: (pin: string) => void
  pinPosting: boolean
  pinSuccess: boolean
  pinError?: any
}
interface Props extends PinProps{
  header: string
  title: string
  transaction: TransactionModel
}



const PanelTransPin: React.FunctionComponent<Props> = ({header, title, onPinSubmit, pinPosting, pinError, pinSuccess, transaction}) => {

  const pinLength = 4
  
  const { t } = useTranslation()
  const [pin, setPin] = useState("")

  const pinValid = pin.length === pinLength

  const counterpartCode = transaction?.counterpartECode || ""
  const counterpartCodeUsed = counterpartCode !== "" || pinSuccess

  const onPinChanged = (value: string, index: number) => {
    setPin(value)
  }

  const submitPin = () => {
    if (pinValid) {
      onPinSubmit(pin)
    }
  }



  return (
        <BoxTrans header={header}
                  title={title}
                  background={"#F4F4F4"}>
          <ColumnCenter>
            <ColumnCenter>
              <HorizontalBox position={"relative"}>
                <PinInput
                  initialValue={counterpartCode}
                  disabled={counterpartCodeUsed}
                  type={"custom"}
                  length={pinLength}
                  autoSelect={true}
                  onChange={onPinChanged}/>

                {pinValid &&
                  <IconCheck color="primary" fontSize={"large"} sx={{position:"absolute", right:"-36px", top:"2px"}}/>
                }
              </HorizontalBox>
              <SpaceBox size={16}/>
              <TextLarge $color={"#001964"}>{t("transaction.complete.confirm.code.text")}</TextLarge>
              <SpaceBox size={8}/>
            </ColumnCenter>

            <LoadingButtonRounded variant="contained"
                                  loading={pinPosting}
                                  onClick={submitPin}
                                  width={"160px"}
                                  startIcon={counterpartCodeUsed && <IconCheck/>}
                                  disabled={!pinValid || counterpartCodeUsed}>
              { counterpartCodeUsed ? t("transaction.label.activated") : t("button.label.confirm")}
            </LoadingButtonRounded>

            <Warning style={{visibility: pinError ? "visible": "hidden"}} severity={"error"}>{t(""+pinError)}</Warning>

          </ColumnCenter>

        </BoxTrans>


  )
}

export default PanelTransPin

