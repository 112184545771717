import React, {FunctionComponent} from "react";
import {CenterFull, SpaceBox, Warning} from "../Containers";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {useMediaQuery} from "@mui/material";
import useLogin from "../../hooks/useLogin";
import {UserLoginModel} from "../../models/user.models";
import FormLogin from "./FormLogin";


interface Props {
  children: any
  user?: UserLoginModel
}

const FormLoginWrapper: FunctionComponent<Props> = ({children, user}) => {

  const {mutate: login, isPending, error} = useLogin()
  const portrait = useMediaQuery("(max-width:640px)")

  const { t } = useTranslation()

  const doLogin = (data: UserLoginModel) => {
    //user = cleanupData(user, ["username", "password", "userType"]) as unknown as UserLoginModel
    login(data)
  }

  return (
    <>

      {children}

      <CenterFull direction={"column"} justify={"space-around"}>

        <Container portrait={portrait}>

          <SpaceBox size={portrait ? 0 : 25}/>

          <h1 style={{color: "#2E62FE"}}>{t("login.title.welcome")}</h1>
          <SpaceBox size={8}/>
          <h2 style={{color: "#2E62FE", whiteSpace:"inherit", textAlign:"center"}}>{t("login.title.instructions")}</h2>

          <FormLogin onSubmit={doLogin} pending={isPending} user={user} fieldWidth={portrait ? "300px" : "400px"}/>

          <Warning style={{visibility: error ? "visible": "hidden"}} severity={"error"}>{""+error}</Warning>

        </Container>

      </CenterFull>
    </>
  )
}

export default FormLoginWrapper

export const Container = styled.div<{portrait: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  /*width: 620px;
  height: 500px;*/
  
  ${props => !props.portrait && `
    padding: 22px 68px;
    border: 1px solid var(--color-primary);
    border-radius: 9px; 
  `}
`;