import {useMutation} from "@tanstack/react-query";
import {MarketplaceUserModel} from "../models/marketplace.models";
import MarketPlaceService from "../services/MarketPlaceService";
import {ProfileModel} from "../models/profile.models";


const registerUser = async (user: MarketplaceUserModel): Promise<ProfileModel> => {
  const { data } = await MarketPlaceService.register(user)
  console.log(data)
  return data
}

/*
{
    "id": "65f7b529c124c3fc485e2e04",
    "userId": "cc15c3b4-6b64-4331-a8c7-c63da1881dc2"
}
 */


const useMarketPlace = (callback?: (result?: any) => void ) => {
  return useMutation({
    mutationFn: (user: MarketplaceUserModel) => registerUser(user),
    onSuccess(result: ProfileModel) {
      callback && callback(result)
    }
  })
}
export default useMarketPlace;

