import React from 'react'
import styled from "@emotion/styled"
import {useTranslation} from "react-i18next"
import {Card, CardContent, CardMedia, TableCell} from "@mui/material"
import {Filler} from "../Containers"

import {TransactionType} from "../../models/transaction.models";

interface Props {
  selected: boolean,
  transType: TransactionType,
  onClick: (transType: TransactionType | null) => void
}

const CellTransType: React.FunctionComponent<Props> = ({ selected= false, transType, onClick }) => {

  const { t } = useTranslation();

  const clickHandler = () => {
    onClick(selected ? null : transType)
  }

  return (
        <Cell>
          <CellCard onClick={clickHandler} selected={selected}>
            <Filler/>
            <CardMedia component="img"
                       sx={{objectFit:"contain", overflow:"initial", width:"initial", maxHeight: "70%", maxWidth:"80%"}}
                       image={transType.icon}/>
            <Filler/>
            <Content>
              <h3>{t(transType.i18n)}</h3>
            </Content>
          </CellCard>
        </Cell>
  );

}

export default CellTransType;



const Cell = styled(TableCell)`
  justify-content: center;
  border-bottom: 0;
  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

const CellCard = styled(Card)<{selected: boolean}>`
  width: 240px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-grigio-chiaro);
  border-radius: 6px;
  border: 5px solid var(--color-grigio-chiaro);
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.10);
  ${props => props.selected && `
    border-color: var(--color-primary);`
  }
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 155px;
    height: 135px;
  }
`;

const Content = styled(CardContent)`
  padding: 0 0 10px 0 !important;
, margin: 0;
`;
