import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import {CenterFull, PageLeft, Warning} from "../lib/components/Containers"

import {useNavigate, useParams} from "react-router-dom"
import Spinner from "../lib/components/Spinner"
import TopBar from "../components/TopBar"
import {IconButton} from "@mui/material"
import IconBack from "../lib/images/svg/back.svg"
import IconRefresh from "@mui/icons-material/Refresh"

import useViewTransaction from "../lib/hooks/useViewTransaction";
import PanelTransPayment from "../lib/components/transactions/PanelTransPayment";
import BoxTransUpload from "../lib/components/transactions/BoxTransUpload";
import useSaveTransaction, {ExchangeCodeParams} from "../lib/hooks/useSaveTransaction";
import PanelTransCodes from "../lib/components/transactions/PanelTransCodes";
import PanelTransConfirm from "../lib/components/transactions/PanelTransConfirm";
import useCompany from "../lib/hooks/useCompany";
import {PaymentModel} from "../lib/models/user.models";
import useFee from "../lib/hooks/useFee";
import PanelTransInfo from '../lib/components/transactions/PanelTransInfo'

import {isBuyerTransaction, isSellerTransaction} from "../lib/utils/TransactionFunctions";


const TransactionView: FunctionComponent = () => {

  const onSaved = () => {
    reload()
  }

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [propertyProofFile, setPropertyProofFile] = useState<File | null>(null)
  const [propertyTransferFile, setPropertyTransferFile] = useState<File | null>(null)
  const {transactionId} = useParams();
  const {transaction, matchedTransaction,
    buyer, seller, operatorManaged
  } = useViewTransaction(transactionId!)
  const {savePropertyProof, savePropertyTransfer,
    saveExchangeCode,  saveExchangeCodes
  } = useSaveTransaction(onSaved)
  const company = useCompany(operatorManaged)
  const fee = useFee(transaction.data?.price)
  const [payment, setPayment] = useState<PaymentModel>()


  const reload = () => {
    navigate(0)
  }

  if (savePropertyProof.isSuccess) {
    reload()
  }

  const {data: currentTransaction} = transaction
  const sellerTransaction = isSellerTransaction(currentTransaction) ? currentTransaction : isSellerTransaction(matchedTransaction.data) ? matchedTransaction.data : undefined
  const buyerTransaction = isBuyerTransaction(currentTransaction) ? currentTransaction : isBuyerTransaction(matchedTransaction.data) ? matchedTransaction.data : undefined

  useEffect( () => {
    if (company.data && fee.data) {
      setPayment({iban: company.data.iban, recipient: company.data.legalName, amount: fee.data.amount})
    }
  }, [company.data, fee.data])

  const isReady = (): boolean => {
    if (operatorManaged === true) {
      return sellerTransaction !== undefined && buyerTransaction !== undefined
    } else if (operatorManaged === false) {
      return currentTransaction !== undefined
    }
    return false
  }

  const canShowUploadPropertyProof = (): boolean => {
    let transaction
    if (operatorManaged === true) {
      transaction = sellerTransaction
    } else if (operatorManaged === false && isSellerTransaction(currentTransaction)) {
      transaction = currentTransaction
    }
    return transaction?.state === 1 && !transaction.propertyProofURL
  }
  const canShowPayment = (): boolean => {
    let transaction
    if (operatorManaged === true) {
      transaction = sellerTransaction
    } else if (operatorManaged === false && isBuyerTransaction(currentTransaction)) {
      transaction = currentTransaction
    }
    return transaction?.state === 3 && payment !== undefined;
  }
  const canShowConfirm = (): boolean =>  operatorManaged === true && sellerTransaction?.state === 4

  const canShowExchange = (): boolean => operatorManaged === false && (currentTransaction?.state === 4 || currentTransaction?.state === 5)

  const canAddParty = (): boolean => {
    if (operatorManaged === true) {
      return sellerTransaction === undefined || buyerTransaction === undefined
    }
    return false
  }


  const goBack = () => {
    navigate(-1)
  }

  const submitPropertyProof = () => {
    if (sellerTransaction) {
      savePropertyProof.mutate({transactionId: sellerTransaction.id, file: propertyProofFile!, userId: sellerTransaction.user})
    }
  }

  const submitPropertyTransfer = () => {
    if (sellerTransaction) {
      savePropertyTransfer.mutate({transactionId: sellerTransaction.id, file: propertyTransferFile!, userId: sellerTransaction.user})
    }
  }

  const submitPin = (pin: string) => {
    if (transaction?.data) {
      saveExchangeCode.mutate({transactionId: currentTransaction!.id, code: pin})
    }
  }

  const submitPins = () => {
    if (sellerTransaction?.exchangeCode && buyerTransaction?.exchangeCode) {
      const params: ExchangeCodeParams[] = []
      params.push({transactionId: sellerTransaction.id, code: buyerTransaction.exchangeCode})
      params.push({transactionId: buyerTransaction.id, code: sellerTransaction.exchangeCode})
      saveExchangeCodes.mutate(params)
    }
  }


  return (

    <PageLeft>
      {transaction.isSuccess && currentTransaction &&
        <>
          <TopBar justify={"space-between"} icon={<IconButton onClick={goBack}><img alt="back" src={IconBack}/></IconButton>}
                title={t("transaction.header.detail")+" "+currentTransaction.licensePlates}>
              <IconButton onClick={reload}><IconRefresh/></IconButton>
          </TopBar>

          <PanelTransInfo transaction={currentTransaction}
                          seller={seller}
                          buyer={buyer}
                          buyerTransactionId={buyerTransaction?.id}
                          sellerTransactionId={sellerTransaction?.id}/>

       { canShowUploadPropertyProof() &&
            <BoxTransUpload header={t("transaction.resume.title")}
                            title={t("transaction.complete.property.proof.text")}
                            onUploadFileChanged={setPropertyProofFile}
                            onUploadFileSubmit={submitPropertyProof}
                            uploading={savePropertyProof.isPending}
                            uploadError={savePropertyProof.error}
                            uploadFile={propertyProofFile}
                            background={"#F4F4F4"}/>}

          { canAddParty() &&
            <></>
          }


          { isReady() &&
            <>

            { canShowPayment() &&
              <PanelTransPayment transaction={sellerTransaction!}
                                 payment={payment!}/>}

            { canShowExchange() &&
              <PanelTransCodes transaction={currentTransaction}

                               onPinSubmit={submitPin}
                               pinPosting={saveExchangeCode.isPending}
                               pinSuccess={saveExchangeCode.isSuccess}
                               pinError={saveExchangeCode.error}

                               onUploadFileChanged={setPropertyTransferFile}
                               onUploadFileSubmit={submitPropertyTransfer}
                               uploading={savePropertyTransfer.isPending}
                               uploadError={savePropertyTransfer.error}
                               uploadFile={propertyTransferFile}/>}

            { canShowConfirm() &&
              <PanelTransConfirm transaction={sellerTransaction!}

                                 onPinSubmit={submitPins}
                                 pinPosting={saveExchangeCodes.isPending}
                                 pinSuccess={saveExchangeCodes.isSuccess}
                                 pinError={saveExchangeCodes.error}

                                 onUploadFileChanged={setPropertyTransferFile}
                                 onUploadFileSubmit={submitPropertyTransfer}
                                 uploading={savePropertyTransfer.isPending}
                                 uploadError={savePropertyTransfer.error}
                                 uploadFile={propertyTransferFile}/>}
            </>}

        </>}


      {transaction.isPending &&
        <CenterFull>
          <Spinner />
        </CenterFull>}

      {transaction.isError &&
        <Warning severity="warning">{transaction["error"]}</Warning>}

    </PageLeft>
  )

}

export default TransactionView


