import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import {ColumnCenter} from "../Containers";
import {useForm} from "react-hook-form";
import {ProfileDataDocument} from "../../models/profile.models";
import {FormContainer} from "../fields/Common";
import FieldsDocumentPortrait from './FieldsDocumentPortrait';
import WrapDocument from "./WrapDocument";



interface Props {
  profile: ProfileDataDocument
  onChanged: (profile: ProfileDataDocument, valid: boolean, dirty: boolean) => void
}

const FormDocumentPortrait: FunctionComponent<Props> = ({profile, onChanged}) => {

  const formHook = useForm({defaultValues: profile, mode: "all"});
  const {t } = useTranslation()

  return (
    <ColumnCenter>

      <h3>{t("profile.title.document")}</h3>

      <FormContainer>
        <WrapDocument formHook={formHook} onChanged={onChanged}>
          <FieldsDocumentPortrait formHook={formHook} profile={profile}/>
        </WrapDocument>
      </FormContainer>

    </ColumnCenter>
  )
}

export default FormDocumentPortrait