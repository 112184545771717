import styled from "@emotion/styled";
import {Alert, Box, Link, LinkProps, LinkTypeMap, Paper, Table} from "@mui/material";
import {FunctionComponent} from "react";
import {AlertProps} from "@mui/material/Alert/Alert";
import Toolbar from "@mui/material/Toolbar";


export const HorizontalBox = styled(Box)<{background?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  ${props => props.background && `
    background-color: ${props.background};
  `};
`;

export const Warning: FunctionComponent<AlertProps> = (props)   => {
  return (
    <CenterFull>
      <Alert {...props} sx={{padding: "0 16px", alignItems: "center"}}/>
    </CenterFull>
  )
}

export const ToolbarWide = styled(Toolbar)<{justify?: string}>`
  width: 100%;
  ${props => props.justify && `
    justify-content: ${props.justify};
  `};
`;

export const TableData = styled(Table)`
  border-collapse: inherit;
  border-spacing: 0 6px;
  max-width: var(--max-page-width);
`;

export const TableScroll = styled(Paper)`
  width: 100%;
  overflow: scroll;
  max-height: calc(100vh - 300px);
`;


export const Page = styled.div`
  max-width: var(--max-page-pad-width);
  
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  
  align-items: center;

  @media screen and (max-width: 599px) {
    padding: 0 var(--pad-page-mobile-width);
  }
  
  @media screen and (min-width: 600px) {
    padding: 0 var(--pad-page-width);
  }
`;

export const PageList = styled(Page)`
  height: calc(100% - 170px);
`;

export const PageLeft = styled(Page)`
  align-items: start;
`;

export const PageCenter = styled(Page)`
  justify-content: center;
`;


export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  margin: 0 auto;
`;

export const ColumnCenter = styled(Column)`
  align-items: center;
  padding: 0 !important;
`;



export const CenterFull = styled.div<{direction?: string, justify?:string}>`
  display: flex;
  //justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  flex: 1;
  ${props => props.direction && `
    flex-direction: ${props.direction};
  `};
  justify-content: ${props => props.justify ? props.justify : "center"};
`;

export const Filler = styled.div`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
`;

export const MarginBox = styled.div<{top?: string, bottom?: string, left?: string, right?: string, width?: string, height?: string}>`
  display: flex;
  flex-grow: 1;
  ${props => props.top && `
    margin-top: ${props.top};
  `};
  ${props => props.bottom && `
    margin-bottom: ${props.bottom};
  `};
  ${props => props.left && `
    margin-left: ${props.left};
  `};
  ${props => props.right && `
    margin-right: ${props.right};
  `};
  ${props => props.width && `
    min-width: ${props.width};
  `};
  ${props => props.height && `
    min-height: ${props.height};
  `};
`;

export const SpaceBox = styled.div<{size: number}>`
  width: ${props => props.size / 16}rem;
  height:${props => props.size / 16}rem;
`;


export const BorderBox = styled.div<{padding?: string, marginBottom?: string, color?: string, background?: string}>`
  border-radius: 6px;
  border: 1px solid   ${props => props.color || "rgba(0, 0, 0, 0.23)"};
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  padding: ${props => props.padding || "30px"};
  width: 100%;
  margin-bottom: ${props => props.marginBottom || "16px"};
  ${props => props.background && `
    background-color: ${props.background};
  `};
`

export const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  row-gap: 30px;
`;

export const BoxSection = styled.div<{padding?: string, background?: string}>` 
  min-width: 292px;
  padding: ${props => props.padding || "0"};
  background-color: ${props => props.background || "#FFF"};
  align-content: start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 32%;
  overflow: hidden;
`

export const Line  = styled.hr`
  border: 1px solid var(--color-grigio-chiaro);
  width: 100%;
  margin: 0;
`

export const ImageFullWidth = styled.img`
  width: 100%;
  height: auto;
`

export const StyledLink = <D extends React.ElementType = LinkTypeMap["defaultComponent"], P = {}>(props: LinkProps<D, P>,) => {
  return (
    <Link {...props} variant={"body1"} sx={{fontWeight: 700, cursor: "pointer", textDecoration: "none"}}>
      {props.children}
    </Link>
  )
}


export const Row = styled.div<{width?: string, align?: string}>`
  display: flex;
  flex-direction: row;
  ${props => props.width && `
    width: ${props.width};
  `};
  ${props => props.align && `
    align-items: ${props.align};
    align-content: ${props.align};
  `};
`;

export const Col = styled.div<{width?: string, align?: string}>`
  display: flex;
  flex-direction: column;
  ${props => props.width && `
    width: ${props.width};
  `};
  ${props => props.align && `
    align-items: ${props.align};
    align-content: ${props.align};
  `};
`;