import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {UserLoginModel} from "../../models/user.models";
import InputTextField from "../fields/InputTextField";
import {HorizontalBox, SpaceBox} from "../Containers";
import IconNext from "@mui/icons-material/ArrowForward";
import styled from "@emotion/styled";
import validator from "validator";
import {LoadingButtonRounded} from "../fields/Common";
import RadioField from "../fields/RadioField";

import {getUserTypes} from "../../utils/ProfileFunctions";

interface Props {
  onSubmit: (user: UserLoginModel) => void
  fieldWidth?: string,
  user?: UserLoginModel
  pending: boolean
}

const FormLogin: FunctionComponent<Props> = ({onSubmit, user, pending, fieldWidth = "400px"}) => {

  const { t } = useTranslation()
  const { register, formState: { errors, isValid }, setValue, getValues}
    = useForm<UserLoginModel>({ defaultValues: user, mode: "onChange" });

  const handleSubmit = () => {
    onSubmit(getValues())
  }


  return (
    <Container>

      <SpaceBox size={40}/>

      {/*userType &&
        <TextField sx={{display: "none"}}
                   defaultValue={userType}
                   {...register("userType") }/>}

      {!userType &&*/}

      <RadioField name={"userType"}
                  label={t("login.label.userType")}
                  required
                  width={fieldWidth}
                  minWidth={fieldWidth}
                  defaultValue={user?.userType ? user.userType : "private"}
                  setValue={setValue}
                  getValues={getValues}
                  error={!!errors["userType"]}
                  register={register}
                  optionLabel={"label"}
                  optionValue={"value"}
                  options={getUserTypes(t)} />

      <InputTextField name={"username"}
                      label={t("login.label.username")}
                      error={!!errors["username"]}
                      width={fieldWidth}
                      required
                      register={register}
                      validator={validator.isEmail}
                      placeholder={t("login.placeholder.username")}/>

      <InputTextField name={"password"}
                      type={"password"}
                      label={t("login.label.password")}
                      width={fieldWidth}
                      required
                      error={!!errors["password"]}
                      register={register}
                      placeholder={t("login.placeholder.password")}/>

      <HorizontalBox m={"10px"}>
        <LoadingButtonRounded loading={pending}
                              onClick={handleSubmit}
                              width={"160px"}
                              variant="contained"
                              endIcon={<IconNext />}
                              disabled={!isValid}>
                              {t("button.label.login")}
        </LoadingButtonRounded>
      </HorizontalBox>

    </Container>
  )
}

export default FormLogin


export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;