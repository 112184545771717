import React from 'react'
import {useTranslation} from "react-i18next"
import {ColumnCenter, SpaceBox, Warning} from "../Containers"
import {TextLarge} from "../Texts"
import {LoadingButtonRounded} from "../fields/Common"
import BoxTrans from "./BoxTrans"
import PinInput from "react-pin-input"
import {TransactionModel} from "../../models/transaction.models";
import IconCheck from "@mui/icons-material/Check"
import CopyClip from "../CopyClip";


interface Props {
  header: string
  title: string
  transaction: TransactionModel
}

const BoxTransCode: React.FunctionComponent<Props> =  ({header, title, transaction}) => {

  const pinLength = 4
  const { t } = useTranslation()
  const exchangeCode = transaction?.exchangeCode || ""
  const exchangeCodeUsed = transaction.counterpartInsertedEcode || false

  return (
    <BoxTrans header={header}
              title={title}
              background={"#F4F4F4"}>
      <ColumnCenter>
        <ColumnCenter>
          <CopyClip content={exchangeCode}
                    size={"medium"}
                    position={"absolute"}>
            <PinInput
              disabled={true}
              type={"custom"}
              regexCriteria={RegExp("[\\s\\S]+")}
              length={pinLength}
              initialValue={exchangeCode}/>
          </CopyClip>
          <SpaceBox size={16}/>
          <TextLarge $color={"#001964"}>{t("transaction.complete.secret.code.text")}</TextLarge>
          <SpaceBox size={8}/>
        </ColumnCenter>

        <LoadingButtonRounded variant="contained"
                              disabled={true}
                              width={"160px"}
                              startIcon={exchangeCodeUsed && <IconCheck/>}
                              sx={{visibility: exchangeCodeUsed ? "visible" : "hidden"}}>
          {t("transaction.label.activated")}
        </LoadingButtonRounded>

        <Warning style={{visibility: "hidden"}}/>

      </ColumnCenter>
    </BoxTrans>
  )
}

export default BoxTransCode


