import React, {useState} from "react";
import {IconButton, InputAdornment} from "@mui/material";
import {StandardTextFieldProps} from "@mui/material/TextField/TextField";
import {HorizontalBox} from "../Containers";
import {ErrorIndicator, FormField, InputText, TextLabel} from "./Common";
import { Visibility, VisibilityOff} from "@mui/icons-material";
import {UseFormRegister} from "react-hook-form";
import {capitalize} from "../../utils/Functions";

interface Props extends StandardTextFieldProps {
  register: UseFormRegister<any>
  readOnly?: boolean
  maxLength?: number
  validator?: (value: string, options?: any) => boolean
  width?: string
  padding?: string
  uppercase?: boolean
}

//https://stackoverflow.com/questions/73312962/antd-force-an-input-field-to-uppercase

const InputTextField: React.FC<Props> = (props) => {

  const [showPassword, setShowPassword] = useState(false);
  const { register, name = "", type,
    required, readOnly, maxLength, error,
    validator, uppercase = false,
    label, width = "320px", padding = "0",
    ...textFieldProps
  } = props;

  const passwordToggler = (
    <InputAdornment position="end">
      <IconButton
        onClick={ () => setShowPassword(!showPassword) }
        edge="end">
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  )

  if (uppercase) {
    textFieldProps.onInput = capitalize
  }

  const validateValue = (value: string): boolean  => {
    if (validator) {
      return validator(value)
    } else {
      return true
    }
  }


  const inputProps = type === "password" ? {endAdornment: passwordToggler} : { readOnly: readOnly}
  const requirement = {validate: validateValue, required: required}
  const fieldProps = {maxLength: maxLength }

  return (
    <FormField width={width} sx={{padding: padding}}>
      <TextLabel position={"start"}>{label}</TextLabel>
      <HorizontalBox>
        <InputText variant={"outlined"} sx={{width: "100%"}}
                   type={ type !== "password" ? type : !showPassword ? "password" : "text" }
                   error={error}
                   {...textFieldProps}
                   {...register(name, requirement)}
                   InputProps={inputProps}
                   inputProps={fieldProps}
        />
        <ErrorIndicator error={error}/>
      </HorizontalBox>
    </FormField>
  )
}

export default InputTextField
